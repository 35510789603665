import React from 'react';
import classes from './Banner.module.scss';
import { useTranslation } from 'react-i18next';
import block_01 from '../../../assets/img/TradingPage/Banner/block_01.png';
import block_02 from '../../../assets/img/TradingPage/Banner/block_02.png';
import bg from '../../../assets/img/TradingPage/Banner/bg.png';
import image from '../../../assets/img/TradingPage/Banner/image.png';
import Button from '../../GlobalComponents/Button/Button';

const Banner = () => {
  const { t } = useTranslation();
  return (
    <section className={classes.bannerWrap}>
      <div className="container">
        <div className={classes.banner}>
          <div className={classes.content}>
            <h1 className={[classes.title, 'font-40'].join(' ')}>
              {t('trading.banner.title')}
            </h1>
            <p className={[classes.text, 'font-20'].join(' ')}>
              {t('trading.banner.text')}
            </p>
            <div className={classes.btn}>
              <Button>{t('btn.open_account')}</Button>
            </div>
          </div>
          <img className={classes.image} src={image} alt="" />
          <img className={classes.block_01} src={block_01} alt="" />
          <img className={classes.block_02} src={block_02} alt="" />
        </div>
      </div>
      <img className={classes.bg} src={bg} alt="" />
    </section>
  );
};

export default Banner;
