import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import Button from "../../GlobalComponents/Button/Button";
import woman from '../../../assets/img/LearnPage/Banner/woman.png';
import phone from '../../../assets/img/LearnPage/Banner/phone.png';
import card_01 from '../../../assets/img/LearnPage/Banner/card_01.png';
import card_02 from '../../../assets/img/LearnPage/Banner/card_02.png';
import arrow from '../../../assets/img/LearnPage/Banner/arrow.png';
import { useTranslation } from "react-i18next";
import { gsap } from "gsap";
gsap.config({
    force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();
    const phoneRef = useRef(null);
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const arrowRef_01 = useRef(null);
    const arrowRef_02 = useRef(null);
    const arrowRef_03 = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.fromTo(phoneRef.current, {
                x: 50,
            }, {
                x: 0,
                duration: 2,
            });
            gsap.fromTo(cardRef_01.current, {
                y: 50,
                autoAlpha: 0,
            }, {
                autoAlpha: 1,
                y: 0,
                delay: 0.5,
                duration: 1.5,
            });
            gsap.fromTo(cardRef_02.current, {
                y: 30,
                autoAlpha: 0,
            }, {
                autoAlpha: 1,
                y: 0,
                delay: 0.5,
                duration: 1.5,
            });
            gsap.fromTo(arrowRef_01.current, {
                y: 140,
                autoAlpha: 0,
            }, {
                autoAlpha: 1,
                y: 0,
                delay: 1,
                duration: 1,
            });
            gsap.fromTo(arrowRef_02.current, {
                y: 100,
                autoAlpha: 0,
            }, {
                autoAlpha: 1,
                y: 0,
                delay: 1,
                duration: 1,
            });
            gsap.fromTo(arrowRef_03.current, {
                y: 180,
                autoAlpha: 0,
            }, {
                autoAlpha: 1,
                y: 0,
                delay: 1,
                duration: 1,
            });
        }
    }, []);
    return (
        <section className={classes.bannerWrap}>
            <div className="container">
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h2 className={[classes.title, 'font-40'].join(' ')}>
                            <span>
                                {t('about.banner.title')}
                            </span>
                        </h2>
                        <div className={[classes.text, 'font-20'].join(' ')}>
                            <p>
                                {t('about.banner.text_01')}
                            </p>
                            <p>
                                {t('about.banner.text_02')}
                            </p>
                        </div>
                        <div className={classes.btn}>
                            <Button>{t('btn.open_account')}</Button>
                        </div>
                    </div>
                    <img className={classes.woman} src={woman} alt=''/>
                    <img ref={phoneRef} className={classes.phone} src={phone} alt=''/>
                    <img ref={cardRef_01} className={classes.card_01} src={card_01} alt=''/>
                    <img ref={cardRef_02} className={classes.card_02} src={card_02} alt=''/>
                    <img ref={arrowRef_01} className={classes.arrow_01} src={arrow} alt=''/>
                    <img ref={arrowRef_02} className={classes.arrow_02} src={arrow} alt=''/>
                    <img ref={arrowRef_03} className={classes.arrow_03} src={arrow} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default Banner;
