import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Banner from "../../components/TradingPage/Banner/Banner";
import UnderBanner from "../../components/TradingPage/UnderBanner/UnderBanner";
import IndustryLeading from "../../components/TradingPage/IndustryLeading/IndustryLeading";
import Faq from "../../components/TradingPage/Faq/Faq";

const TradingPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <HelmetProvider>
            <Helmet>
                <title>Trading</title>
                <meta name="title" content="Start Trading at Curion Finance | Get Your Profit Right Away" />
                <meta property="og:title" content="Start Trading at Curion Finance | Get Your Profit Right Away" />
                <meta property="twitter:title" content="Start Trading at Curion Finance | Get Your Profit Right Away" />
                
                <meta name="description" content="Unlock all trading opportunities at Curion Finance forex broker. Create your strategy. Have access to the top-notch trading assets to generate more profit each session"/>
                <meta property="og:description" content="Unlock all trading opportunities at Curion Finance forex broker. Create your strategy. Have access to the top-notch trading assets to generate more profit each session"/>
                <meta property="twitter:description" content="Unlock all trading opportunities at Curion Finance forex broker. Create your strategy. Have access to the top-notch trading assets to generate more profit each session" />

                <meta property="og:image" content="preview.png" />
                <meta property="og:type" content="website" />
                <meta property="twitter:image" content="preview.png" />
                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="ovf-hidden">
                <Header />
                <Banner />
                <div className="mt container">
                    <UnderBanner />
                </div>
                <IndustryLeading />
                <Faq />
                <Footer city />
            </div>
        </HelmetProvider>
    );
};

export default TradingPage;
