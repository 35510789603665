import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/AccountTypesPage/Banner/Banner";
import AccountTypes from "../../components/AccountTypesPage/AccountTypes/AccountTypes";
import ProfessionalClient from "../../components/AccountTypesPage/ProfessionalClient/ProfessionalClient";
import Faq from "../../components/AccountTypesPage/Faq/Faq";

const AccountTypesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <title>Account Types</title>
        <meta name="title" content="Choose the Best Forex Trading Account with Curion Finance" />
        <meta property="og:title" content="Choose the Best Forex Trading Account with Curion Finance" />
        <meta property="twitter:title" content="Choose the Best Forex Trading Account with Curion Finance" />

        <meta name="description" content="Find the perfect Forex trading account that suits your needs with Curion Finance. We offer a wide range of account types, each with its own set of features and benefits." />
        <meta property="og:description" content="Find the perfect Forex trading account that suits your needs with Curion Finance. We offer a wide range of account types, each with its own set of features and benefits." />
        <meta property="twitter:description" content="Find the perfect Forex trading account that suits your needs with Curion Finance. We offer a wide range of account types, each with its own set of features and benefits." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <AccountTypes />
        <ProfessionalClient />
        <Faq />
        <Footer city />
      </div>
    </HelmetProvider>
  );
};

export default AccountTypesPage;
