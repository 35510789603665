import React from "react";
import classes from './AccountTypes.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from '../../GlobalComponents/Button/Button';
import icon_01 from '../../../assets/img/AccountTypesPage/Accounts/icon_01.svg';
import icon_02 from '../../../assets/img/AccountTypesPage/Accounts/icon_02.svg';
import icon_03 from '../../../assets/img/AccountTypesPage/Accounts/icon_03.svg';
import icon_04 from '../../../assets/img/AccountTypesPage/Accounts/icon_04.svg';

const rows = [
    [
        'new_acc_types_row_01_col_01',
        '10-15%',
        '15-35%',
        '35-50%',
        '50-80%',
    ],
    [
        'new_acc_types_row_02_col_01',
        'CFD',
        'CFD',
        'CFD',
        'CFD',
    ],
    [
        'new_acc_types_row_03_col_01',
        '10%',
        '20%',
        '30%',
        '40%',
    ],
    [
        'new_acc_types_row_04_col_01',
        '1:1000',
        '1:50',
        '1:25',
        '1:25',
    ],
    [
        'new_acc_types_row_05_col_01',
        'new_acc_types_row_05_col_02',
        'new_acc_types_row_05_col_03',
        'new_acc_types_row_05_col_04',
        'new_acc_types_row_05_col_05',
    ],
    [
        'new_acc_types_row_06_col_01',
        '-',
        '-',
        'new_acc_types_row_06_col_04',
        'new_acc_types_row_06_col_05',
    ],
    [
        'new_acc_types_row_07_col_01',
        'new_acc_types_row_07_standart',
        'new_acc_types_row_07_standart',
        'new_acc_types_row_07_standart',
        '24/7',
    ],
    [
        'new_acc_types_row_08_col_01',
        '10%',
        '10%',
        '10%',
        '5%',
    ],
    [
        'new_acc_types_row_09_col_01',
        '-',
        'new_acc_types_row_09_yes',
        'new_acc_types_row_09_yes',
        'new_acc_types_row_09_yes',
    ],
    [
        'new_acc_types_row_10_col_01',
        '-',
        '-',
        '-',
        'new_acc_types_row_09_yes',
    ],
];

const headerCols = [
    {
        icon: icon_01,
        title: 'SILVER',
        subtitle: 'new_acc_types_header_01_subtitle',
        text: 'new_acc_types_header_01_text',
    },
    {
        icon: icon_02,
        title: 'GOLD',
        subtitle: 'new_acc_types_header_02_subtitle',
        text: 'new_acc_types_header_02_text',
    },
    {
        icon: icon_03,
        title: 'PREMIUM',
        subtitle: 'new_acc_types_header_03_subtitle',
        text: 'new_acc_types_header_03_text',
    },
    {
        icon: icon_04,
        title: 'VIP',
        subtitle: 'new_acc_types_header_04_subtitle',
        text: 'new_acc_types_header_04_text',
    },
];

const AccountTypes = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.accountTypes}>
            <div className="container">
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    {t('account_types.accounts.title')}
                </h2>
                <p className={`${classes.text} font-20`}>
                    {t('account_types.accounts.text')}
                </p>
            </div>
            <div className={[classes.container, 'container'].join(' ')}>
                <div className={classes.contentWr}>
                    <div className={classes.headerContent}>
                        <div className={classes.row}>
                            <div></div>
                            {headerCols.map((col, index) =>
                                <div key={index} className={classes.col}>
                                    <img className={classes.colIcon} src={col.icon} alt='' />
                                    <div className={classes.colTitle}>
                                        {col.title}
                                    </div>
                                    <div className={classes.colSubtitle}>
                                        {t(col.subtitle)}
                                    </div>
                                    <div className={classes.colBtn}>
                                        <Button>
                                            {t('btn.open_account')}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={classes.content}>
                        {rows.map((row, rowIndex) =>
                            <div key={rowIndex} className={classes.row}>
                                {row.map((col, colIndex) =>
                                    <div key={colIndex} className={classes.col}>
                                        <p>
                                            <Trans>
                                                {t(col)}
                                            </Trans>
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AccountTypes;
