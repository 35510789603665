import React, { useState } from "react";
import classes from './WhatSpecial.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/LearnPage/WhatSpecial/image.png';
import Button from "../../GlobalComponents/Button/Button";

const tabs = [
    {
        title: 'about.spec.0.title',
        text: 'about.spec.0.text',
    },
    {
        title: 'about.spec.1.title',
        text: 'about.spec.1.text',
    },
    {
        title: 'about.spec.2.title',
        text: 'about.spec.2.text',
    },
    {
        title: 'about.spec.3.title',
        text: 'about.spec.3.text',
    },
    {
        title: 'about.spec.4.title',
        text: 'about.spec.4.text',
    },
];

const WhatSpecial = () => {
    const { t } = useTranslation();
    const [ currentTab, setCurrentTab ] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.whatSpecial}>
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    <Trans>
                        {t('about.spec.title')}
                    </Trans>
                </h2>
                <div className={classes.content}>
                    <div className={classes.buttons}>
                        {tabs.map((btn, index) =>
                            <div 
                                key={index} 
                                className={`${classes.btn} ${index === currentTab && classes.btnActive}`}
                                onClick={() => setCurrentTab(index)}
                            >
                                <span>
                                    {t(btn.title)}
                                </span>
                            </div>
                        )}
                    </div>
                    <p className={classes.text}>
                        {t(tabs[currentTab].text)}
                    </p>
                    <div className={classes.btnWrap}>
                        <Button secondary>{t('btn.trade_now')}</Button>
                    </div>
                    <img className={classes.image} src={image} alt=''/>
                </div>
                <div className={[classes.mobContent, 'font-20'].join(' ')}>
                    {tabs.map((tab, index) =>
                        <div key={index} className={classes.block}>
                            <p>
                                <b>
                                    {t(tab.title)}
                                </b>
                            </p>
                            <p>
                                {t(tab.text)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default WhatSpecial;
