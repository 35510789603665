import React, { useEffect, useRef, useState } from "react";
import classes from "./Faq.module.scss";
import { useTranslation } from "react-i18next";
import image_01 from "../../../assets/img/TradingPage/Faq/image_01.png";
import image_02 from "../../../assets/img/TradingPage/Faq/image_02.png";
import image_03 from "../../../assets/img/TradingPage/Faq/image_03.png";
import image_04 from "../../../assets/img/TradingPage/Faq/image_04.png";
import image_05 from "../../../assets/img/TradingPage/Faq/image_05.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const tabs = [
  {
    id: 0,
    title: "faq.block_03.0.title",
    text: "faq.block_03.0.text",
  },
  {
    id: 1,
    title: "faq.block_03.1.title",
    text: "faq.block_03.1.text",
  },
  {
    id: 2,
    title: "faq.block_03.2.title",
    text: "faq.block_03.2.text",
  },
  {
    id: 3,
    title: "faq.block_03.3.title",
    text: "faq.block_03.3.text",
  },
  {
    id: 4,
    title: "faq.block_03.4.title",
    text: "faq.block_03.4.text",
  },
  {
    id: 5,
    title: "faq.block_03.5.title",
    text: "faq.block_03.5.text",
  },
  {
    id: 6,
    title: "faq.block_03.6.title",
    text: "faq.block_03.6.text",
  },
];

const Faq = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(-1);
  const imageRef_01 = useRef(null);
  const imageRef_02 = useRef(null);
  const imageRef_03 = useRef(null);
  const imageRef_04 = useRef(null);
  const imageRef_05 = useRef(null);
  const imageWrapRef_01 = useRef(null);
  const imageWrapRef_02 = useRef(null);
  const imageWrapRef_03 = useRef(null);
  const imageWrapRef_04 = useRef(null);
  const imageWrapRef_05 = useRef(null);
  const openTab = (index) => {
    if (currentTab === index) {
      setCurrentTab(-1);
      return;
    }
    setCurrentTab(index);
  };
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().fromTo(
        imageRef_01.current,
        {
          x: -50,
          y: 100,
        },
        {
          x: 0,
          y: 0,
          scrollTrigger: {
            trigger: imageWrapRef_01.current,
            end: "+=200px",
            scrub: 3,
          },
        }
      );
      gsap.timeline().fromTo(
        imageRef_02.current,
        {
          x: -100,
          y: 100,
        },
        {
          x: 0,
          y: 0,
          scrollTrigger: {
            trigger: imageWrapRef_02.current,
            end: "+=200px",
            scrub: 3,
          },
        }
      );
      gsap.timeline().fromTo(
        imageRef_03.current,
        {
          x: -10,
          y: 100,
        },
        {
          x: 0,
          y: 0,
          scrollTrigger: {
            trigger: imageWrapRef_03.current,
            end: "+=200px",
            scrub: 3,
          },
        }
      );
      gsap.timeline().fromTo(
        imageRef_04.current,
        {
          x: 80,
          y: 100,
        },
        {
          x: 0,
          y: 0,
          scrollTrigger: {
            trigger: imageWrapRef_04.current,
            end: "+=200px",
            scrub: 3,
          },
        }
      );
      gsap.timeline().fromTo(
        imageRef_05.current,
        {
          x: 50,
          y: 100,
        },
        {
          x: 0,
          y: 0,
          scrollTrigger: {
            trigger: imageWrapRef_05.current,
            end: "+=200px",
            scrub: 3,
          },
        }
      );
    }
  }, []);
  return (
    <section className="container mt">
      <div className={classes.faqWrap}>
        <h2 className={[classes.title, "font-40"].join(" ")}>
        {t('faq.block')} 3: <span>{t('faq.banner.tab_03')}</span>
        </h2>
        <div className={classes.tabs}>
          {tabs.map((tab) => (
            <div
              className={`${classes.tab} ${
                currentTab === tab.id && classes.tabActive
              } no-select`}
              onClick={() => openTab(tab.id)}
              key={tab.id}
            >
              <div className={classes.tabHeader}>
                <div className={classes.tabHeaderText}>{t(tab.title)}</div>
                <div className={classes.tabStatus}></div>
              </div>
              <div className={classes.tabContent}>
                <p>{t(tab.text)}</p>
              </div>
            </div>
          ))}
        </div>
        <div
          ref={imageWrapRef_01}
          className={[classes.image, classes.image_01].join(" ")}
        >
          <img ref={imageRef_01} src={image_01} alt="" />
        </div>
        <div
          ref={imageWrapRef_02}
          className={[classes.image, classes.image_02].join(" ")}
        >
          <img ref={imageRef_02} src={image_02} alt="" />
        </div>
        <div
          ref={imageWrapRef_03}
          className={[classes.image, classes.image_03].join(" ")}
        >
          <img ref={imageRef_03} src={image_03} alt="" />
        </div>
        <div
          ref={imageWrapRef_04}
          className={[classes.image, classes.image_04].join(" ")}
        >
          <img ref={imageRef_04} src={image_04} alt="" />
        </div>
        <div
          ref={imageWrapRef_05}
          className={[classes.image, classes.image_05].join(" ")}
        >
          <img ref={imageRef_05} src={image_05} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Faq;
