import { useEffect, useState, useRef } from 'react';
import classes from './Three.module.scss';
import img1 from '../../../assets/img/MainPage/4/1.png';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import { Trans, useTranslation } from 'react-i18next';
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const Three = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: 'top bottom',
          end: 'top center',
          scrub: 2,
        },
        y: 0,
        autoAlpha: 1,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  const array = [
    { id: 1, items: ['EUR/USD', '0.2', '0', '-100%'] },
    { id: 2, items: ['USD/JPY', '0.2', '0', '-100%'] },
    { id: 3, items: ['GBP/USD', '0.5', '0.3', '-60%'] },
    { id: 4, items: ['AUD/USD', '0.4', '0.2', '-50%'] },
    { id: 5, items: ['USD/CHF', '0.4', '0.2', '-50%'] },
    { id: 6, items: ['USD/CAD', '0.5', '0.3', '-60%'] },
    { id: 7, items: ['EUR/GBP', '0.6', '0.3', '-50%'] },
    { id: 8, items: ['NZD/USD', '0.5', '0.3', '-60%'] },
    { id: 9, items: ['EUR/JPY', '0.4', '0.2', '-50%'] },
  ];

  return (
    <div className={[classes.three, 'mt'].join(' ')} ref={col1}>
      <div className={[classes.threeBody, ''].join(' ')}>
        <div className="container">
          <div className={[classes.threeImg, ''].join(' ')} ref={monetRef1}>
            <img src={img1} alt="" />
          </div>

          <h2 className={[classes.threeTitle, 'font-40'].join(' ')}>
            <Trans>{t('home.spreads.title')}</Trans>
          </h2>
          <div className={[classes.threeTableWrap, ''].join(' ')}>
            <div className={[classes.threeTable, ''].join(' ')}>
              <div className={[classes.threeTableHeader, ''].join(' ')}>
                <p className={[classes.threeTableHeaderItem, ''].join(' ')}></p>
                <p className={[classes.threeTableHeaderItem, ''].join(' ')}>
                  <Trans>{t('home.spreads.header_01')}</Trans>
                </p>
                <p className={[classes.threeTableHeaderItem, ''].join(' ')}>
                  <Trans>{t('home.spreads.header_02')}</Trans>
                </p>
                <p className={[classes.threeTableHeaderItem, ''].join(' ')}>
                  <Trans>{t('home.spreads.header_03')}</Trans>
                </p>
              </div>
              {array.map((i) => {
                return (
                  <div
                    className={[classes.threeTableRow, ''].join(' ')}
                    key={i.id}
                  >
                    {i.items.map((item, index) => {
                      return (
                        <p
                          key={index}
                          className={[classes.threeTableRowItem, ''].join(' ')}
                        >
                          {item}
                        </p>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
