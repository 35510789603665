import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import First from "../../components/FaqComponents/First/First";
import Second from "../../components/FaqComponents/Second/Second";

const FaqPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <title>FAQ & Support</title>
        <meta
          name="title"
          content="Frequently Asked Questions & Support | Curion Finance"
        />
        <meta
          property="og:title"
          content="Frequently Asked Questions & Support | Curion Finance"
        />
        <meta
          property="twitter:title"
          content="Frequently Asked Questions & Support | Curion Finance"
        />

        <meta
          name="description"
          content="Our powerful and intuitive trading platform offers a seamless trading experience. Enjoy advanced charting tools, real-time market data, and fast execution speeds."
        />
        <meta
          property="og:description"
          content="Our powerful and intuitive trading platform offers a seamless trading experience. Enjoy advanced charting tools, real-time market data, and fast execution speeds."
        />
        <meta
          property="twitter:description"
          content="Our powerful and intuitive trading platform offers a seamless trading experience. Enjoy advanced charting tools, real-time market data, and fast execution speeds."
        />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <First />
        <Second />
        <Footer city={true} />
      </div>
    </HelmetProvider>
  );
};

export default FaqPage;
