import React from 'react';
import classes from './Five.module.scss';
import { useTranslation } from 'react-i18next';

const Five = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.five, 'mt'].join(' ')}>
      <div className={[classes.fiveBody, ''].join(' ')}>
        <div className="container">
          <div className={[classes.fiveRow, ''].join(' ')}>
            <div className={[classes.fiveRowLeft, ''].join(' ')}>
              <h3 className={[classes.fiveRowLeftTitle, 'font-40'].join(' ')}>
                {t('learn.using.title')}
              </h3>
              <p className={[classes.fiveRowLeftText, 'font-20'].join(' ')}>
                {t('learn.using.text_01')}
              </p>
              <p className={[classes.fiveRowLeftText, 'font-20'].join(' ')}>
                {t('learn.using.text_02')}
              </p>
            </div>
            <div className={[classes.fiveRowRight, ''].join(' ')}>
              <div className={[classes.fiveRowRightTable, ''].join(' ')}>
                <div className={[classes.fiveRowRightTableRow, ''].join(' ')}>
                  <p
                    className={[classes.fiveRowRightTableRowItem, ''].join(' ')}
                  >
                    {t('learn.using.row_01.title')}
                  </p>
                  <p
                    className={[classes.fiveRowRightTableRowItem, ''].join(' ')}
                  >
                    Control+T
                  </p>
                  <p
                    className={[classes.fiveRowRightTableRowItem, ''].join(' ')}
                  >
                    {t('learn.using.row_01.text')}
                  </p>
                </div>
                <div className={[classes.fiveRowRightTableRow, ''].join(' ')}>
                  <p
                    className={[classes.fiveRowRightTableRowItem, ''].join(' ')}
                  >
                    {t('learn.using.row_02.title')}
                  </p>
                  <p
                    className={[classes.fiveRowRightTableRowItem, ''].join(' ')}
                  >
                    Control+N
                  </p>
                  <p
                    className={[classes.fiveRowRightTableRowItem, ''].join(' ')}
                  >
                    {t('learn.using.row_02.text')}
                  </p>
                </div>
                <div className={[classes.fiveRowRightTableRow, ''].join(' ')}>
                  <p
                    className={[classes.fiveRowRightTableRowItem, ''].join(' ')}
                  >
                    {t('learn.using.row_03.title')}
                  </p>
                  <p
                    className={[classes.fiveRowRightTableRowItem, ''].join(' ')}
                  >
                    Control+M
                  </p>
                  <p
                    className={[classes.fiveRowRightTableRowItem, ''].join(' ')}
                  >
                    {t('learn.using.row_03.text')}
                  </p>
                </div>
                <div className={[classes.fiveRowRightTableRow, ''].join(' ')}>
                  <p
                    className={[classes.fiveRowRightTableRowItem, ''].join(' ')}
                  >
                    {t('learn.using.row_04.title')}
                  </p>
                  <p
                    className={[classes.fiveRowRightTableRowItem, ''].join(' ')}
                  >
                    Control+D
                  </p>
                  <p
                    className={[classes.fiveRowRightTableRowItem, ''].join(' ')}
                  >
                    {t('learn.using.row_04.text')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Five;
