import React from 'react';
import classes from './Four.module.scss';
import Button from '../../GlobalComponents/Button/Button';
import { Trans, useTranslation } from 'react-i18next';

const Four = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.four, 'mt'].join(' ')}>
      <div className={[classes.fourBody, ''].join(' ')}>
        <div className="container">
          <div className={[classes.four, ''].join(' ')}>
            <h2 className={[classes.fourTitle, 'font-40'].join(' ')}>
              <Trans>{t('home.tigh.title')}</Trans>
            </h2>
            <div className={[classes.fourRow, ''].join(' ')}>
              <div className={[classes.fourRowLeft, ''].join(' ')}>
                <p className={[classes.fourRowLeftText, 'font-20'].join(' ')}>
                  <Trans>{t('home.tigh.text_01')}</Trans>
                </p>
                <h3 className={[classes.fourRowLeftTitle, 'font-20'].join(' ')}>
                  <Trans>{t('home.tigh.text_02')}</Trans>
                </h3>
                <p className={[classes.fourRowLeftText, 'font-20'].join(' ')}>
                  <Trans>{t('home.tigh.text_03')}</Trans>
                </p>
                <div className={[classes.fourRowLeftBtn, ''].join(' ')}>
                  <Button>{t('btn.trade_now')}</Button>
                </div>
              </div>
              <div className={[classes.fourRowRight, ''].join(' ')}>
                <h3
                  className={[classes.fourRowRightTitle, 'font-20'].join(' ')}
                >
                  <Trans>{t('home.tigh.text_04')}</Trans>
                </h3>
                <p className={[classes.fourRowRightText, 'font-20'].join(' ')}>
                  <Trans>{t('home.tigh.text_05')}</Trans>
                </p>
                <h3
                  className={[classes.fourRowRightTitle, 'font-20'].join(' ')}
                >
                  <Trans>{t('home.tigh.text_06')}</Trans>
                </h3>
                <p className={[classes.fourRowRightText, 'font-20'].join(' ')}>
                  <Trans>{t('home.tigh.text_07')}</Trans>
                </p>
                <div
                  className={[classes.fourRowLeftBtn, classes.mob, ''].join(
                    ' '
                  )}
                >
                  <Button>{t('btn.trade_now')}</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
