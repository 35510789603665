import React from 'react';
import classes from './Four.module.scss';
import img1 from '../../../assets/img/AboutPage/4/4.png';
import { Trans, useTranslation } from 'react-i18next';

const Four = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.four, 'mt'].join(' ')}>
      <div className={[classes.fourBody, ''].join(' ')}>
        <div className="container">
          <div className={[classes.fourRow, ''].join(' ')}>
            <div className={[classes.fourRowLeft, ''].join(' ')}>
              <img src={img1} alt="" />
            </div>
            <div className={[classes.fourRowRight, ''].join(' ')}>
              <h3 className={[classes.fourRowRightTitle, 'font-20'].join(' ')}>
                <Trans>{t('learn.know.title_01')}</Trans>
              </h3>
              <p className={[classes.fourRowRightText, 'font-20'].join(' ')}>
                <Trans>{t('learn.know.text_01')}</Trans>
              </p>
              <h3 className={[classes.fourRowRightTitle, 'font-20'].join(' ')}>
                <Trans>{t('learn.know.title_02')}</Trans>
              </h3>
              <p className={[classes.fourRowRightText, 'font-20'].join(' ')}>
                <Trans>{t('learn.know.text_02')}</Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
