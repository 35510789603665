import React from 'react';
import classes from './Second.module.scss';
import { useTranslation } from 'react-i18next';

const Second = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.second, 'mt'].join(' ')}>
      <div className={[classes.secondBody, ''].join(' ')}>
        <div className="container">
          <form
            className={[classes.secondForm, ''].join(' ')}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <h2 className={[classes.secondFormTitle, 'font-45'].join(' ')}>
              <span>{t('faq.contact_us.title')}</span>
            </h2>
            <div className={[classes.secondFormInput, ''].join(' ')}>
              <input type="text" placeholder={t('faq.contact_us.name')} />
            </div>
            <div className={[classes.secondFormInput, ''].join(' ')}>
              <input type="text" placeholder={t('faq.contact_us.phone')} />
            </div>
            <div className={[classes.secondFormInput, ''].join(' ')}>
              <input type="text" placeholder="E-mail" />
            </div>
            <button className={[classes.secondFormButton, ''].join(' ')}>
              {t('btn.start_trading')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Second;
