import React from 'react';
import classes from './Six.module.scss';
import img1 from '../../../assets/img/AboutPage/5/1.png';
import { useTranslation } from 'react-i18next';

const Six = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.six, 'mt'].join(' ')}>
      <div className={[classes.sixBody, ''].join(' ')}>
        <div className="container">
          <h3 className={[classes.sixTitle, 'font-40'].join(' ')}>
            {t('learn.more.title')}
          </h3>
          <div className={[classes.sixRow, ''].join(' ')}>
            <div className={[classes.sixRowLeft, ''].join(' ')}>
              <div className={[classes.sixRowLeftImg, ''].join(' ')}>
                <img src={img1} alt="More About Shortcuts" />
              </div>
              <p className={[classes.sixRowLeftText, 'font-20'].join(' ')}>
                {t('learn.more.text')}
              </p>
              <div className={[classes.sixRowLeftTableWrap, ''].join(' ')}>
                <div className={[classes.sixRowLeftTable, ''].join(' ')}>
                  <div className={[classes.sixRowLeftTableRow, ''].join(' ')}>
                    <p
                      className={[classes.sixRowLeftTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      +
                    </p>
                    <p
                      className={[classes.sixRowLeftTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      {t('learn.more.li_01')}
                    </p>
                  </div>
                  <div className={[classes.sixRowLeftTableRow, ''].join(' ')}>
                    <p
                      className={[classes.sixRowLeftTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      -
                    </p>
                    <p
                      className={[classes.sixRowLeftTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      {t('learn.more.li_02')}
                    </p>
                  </div>
                  <div className={[classes.sixRowLeftTableRow, ''].join(' ')}>
                    <p
                      className={[classes.sixRowLeftTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      Control+F
                    </p>
                    <p
                      className={[classes.sixRowLeftTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      {t('learn.more.li_03')}
                    </p>
                  </div>
                  <div className={[classes.sixRowLeftTableRow, ''].join(' ')}>
                    <p
                      className={[classes.sixRowLeftTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      Control+Drag
                    </p>
                    <p
                      className={[classes.sixRowLeftTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      {t('learn.more.li_04')}
                    </p>
                  </div>
                  <div className={[classes.sixRowLeftTableRow, ''].join(' ')}>
                    <p
                      className={[classes.sixRowLeftTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      Alt+Drag
                    </p>
                    <p
                      className={[classes.sixRowLeftTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      {t('learn.more.li_05')}
                    </p>
                  </div>
                  <div className={[classes.sixRowLeftTableRow, ''].join(' ')}>
                    <p
                      className={[classes.sixRowLeftTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      Alt+T
                    </p>
                    <p
                      className={[classes.sixRowLeftTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      {t('learn.more.li_06')}
                    </p>
                  </div>
                  <div className={[classes.sixRowLeftTableRow, ''].join(' ')}>
                    <p
                      className={[classes.sixRowLeftTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      Hotkeys
                    </p>
                    <p
                      className={[classes.sixRowLeftTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      {t('learn.more.li_07')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={[classes.sixRowRight, ''].join(' ')}>
              <p className={[classes.sixRowRightText, 'font-20'].join(' ')}>
                {t('learn.more.text_02')}
              </p>
              <div className={[classes.sixRowRightTableWrap, ''].join(' ')}>
                <div className={[classes.sixRowRightTable, ''].join(' ')}>
                  <div className={[classes.sixRowRightTableRow, ''].join(' ')}>
                    <p
                      className={[classes.sixRowRightTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      F8
                    </p>
                    <p
                      className={[classes.sixRowRightTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      {t('learn.more.li_08')}
                    </p>
                  </div>
                  <div className={[classes.sixRowRightTableRow, ''].join(' ')}>
                    <p
                      className={[classes.sixRowRightTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      F9
                    </p>
                    <p
                      className={[classes.sixRowRightTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      {t('learn.more.li_09')}
                    </p>
                  </div>
                  <div className={[classes.sixRowRightTableRow, ''].join(' ')}>
                    <p
                      className={[classes.sixRowRightTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      F11
                    </p>
                    <p
                      className={[classes.sixRowRightTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      {t('learn.more.li_10')}
                    </p>
                  </div>
                  <div className={[classes.sixRowRightTableRow, ''].join(' ')}>
                    <p
                      className={[classes.sixRowRightTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      Alt+1
                    </p>
                    <p
                      className={[classes.sixRowRightTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      {t('learn.more.li_11')}
                    </p>
                  </div>
                  <div className={[classes.sixRowRightTableRow, ''].join(' ')}>
                    <p
                      className={[classes.sixRowRightTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      Alt+2
                    </p>
                    <p
                      className={[classes.sixRowRightTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      {t('learn.more.li_12')}
                    </p>
                  </div>
                  <div className={[classes.sixRowRightTableRow, ''].join(' ')}>
                    <p
                      className={[classes.sixRowRightTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      Alt+3
                    </p>
                    <p
                      className={[classes.sixRowRightTableRowColumn, ''].join(
                        ' '
                      )}
                    >
                      {t('learn.more.li_13')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Six;
