import React from 'react';
import classes from './Seven.module.scss';
import img1 from '../../../assets/img/AboutPage/6/1.png';
import { useTranslation } from 'react-i18next';

const Seven = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.seven, 'mt'].join(' ')}>
      <div className={[classes.sevenBody, ''].join(' ')}>
        <div className="container">
          <div className={[classes.sevenRow, ''].join(' ')}>
            <div className={[classes.sevenImg, ''].join(' ')}>
              <img src={img1} alt="" />
            </div>
            <div className={[classes.sevenRowLeft, ''].join(' ')}>
              <h3 className={[classes.sevenRowLeftTitle, 'font-40'].join(' ')}>
                {t('learn.tech.left_title')}
              </h3>
              <p className={[classes.sevenRowLeftText, 'font-20'].join(' ')}>
                {t('learn.tech.left_text_01')}
              </p>
              <p className={[classes.sevenRowLeftText, 'font-20'].join(' ')}>
                {t('learn.tech.left_text_02')}
              </p>
            </div>
            <div className={[classes.sevenRowRight, ''].join(' ')}>
              <h3 className={[classes.sevenRowRightTitle, 'font-40'].join(' ')}>
                {t('learn.tech.right_title')}
              </h3>
              <p className={[classes.sevenRowRightText, 'font-20'].join(' ')}>
                {t('learn.tech.right_text_01')}
              </p>
              <p className={[classes.sevenRowRightText, 'font-20'].join(' ')}>
                {t('learn.tech.right_text_02')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Seven;
