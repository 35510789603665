import React from "react";
import classes from "./First.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import img1 from "../../../assets/img/AboutPage/1/1.png";
import { Trans, useTranslation } from "react-i18next";

const First = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.first, ""].join(" ")}>
      <div className={[classes.firstBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.firstRow, ""].join(" ")}>
            <div className={[classes.firstRowLeft, ""].join(" ")}>
              <h2 className={[classes.firstRowLeftTitle, "font-40"].join(" ")}>
                <Trans>
                  {t('learn.banner.title')}
                </Trans>
              </h2>
              <p className={[classes.firstRowLeftText, "font-20"].join(" ")}>
                {t('learn.banner.text')}
              </p>
              <div
                className={[classes.firstRowLeftBtn, classes.mb, ""].join(" ")}
              >
                <Button>{t('btn.open_account')}</Button>
              </div>
              <h2 className={[classes.firstRowLeftTitle, "font-40"].join(" ")}>
                <Trans>
                  {t('learn.banner.step_1.title')}
                </Trans>
              </h2>
              <p className={[classes.firstRowLeftText, "font-20"].join(" ")}>
                {t('learn.banner.step_1.text')}
              </p>
              <div className={[classes.firstRowLeftBtn, ""].join(" ")}>
                <Button>{t('btn.open_account')}</Button>
              </div>
            </div>
            <div className={[classes.firstRowRight, ""].join(" ")}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
