import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Banner from "../../components/LearnPage/Banner/Banner";
import GlobalReach from "../../components/LearnPage/GlobalReach/GlobalReach";
import WhatSpecial from "../../components/LearnPage/WhatSpecial/WhatSpecial";
import LeadingBroker from "../../components/LearnPage/LeadingBroker/LeadingBroker";
import CareersAt from "../../components/LearnPage/CareersAt/CareersAt";

const LearnPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <HelmetProvider>
            <Helmet>
                <title>About Us</title>
                <meta name="title" content="" />
                <meta property="og:title" content="" />
                <meta property="twitter:title" content="" />
                
                <meta name="description" content=""/>
                <meta property="og:description" content=""/>
                <meta property="twitter:description" content="" />

                <meta property="og:image" content="preview.png" />
                <meta property="og:type" content="website" />
                <meta property="twitter:image" content="preview.png" />
                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="ovf-hidden">
                <Header />
                <Banner />
                <GlobalReach />
                <WhatSpecial />
                <LeadingBroker />
                <CareersAt />
                <Footer />
            </div>
        </HelmetProvider>
    );
};

export default LearnPage;
