import React from "react";
import classes from "./Second.module.scss";
import img1 from "../../../assets/img/AboutPage/2/1.png";
import Button from "../../GlobalComponents/Button/Button";
import { Trans, useTranslation } from "react-i18next";

const Second = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.second, "mt"].join(" ")}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.secondRow, ""].join(" ")}>
            <div className={[classes.secondRowLeft, ""].join(" ")}>
              <img src={img1} alt="" />
            </div>
            <div className={[classes.secondRowRight, ""].join(" ")}>
              <h3
                className={[classes.secondRowRightTitle, "font-40"].join(" ")}
              >
                <Trans>
                  {t('learn.step_2.title')}
                </Trans>
              </h3>
              <p
                className={[classes.secondRowRightSubtitle, "font-20"].join(
                  " "
                )}
              >
                {t('learn.step_2.text')}
              </p>
              <div className={[classes.secondRowRightBtn, ""].join(" ")}>
                <Button>{t('btn.fund')}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
