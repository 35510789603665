import logo from '../assets/img/logo.svg';
import home_baner_01 from '../assets/img/MainPage/1/1.png';
import home_baner_02 from '../assets/img/MainPage/1/2.png';
import home_baner_03 from '../assets/img/MainPage/1/3.png';
import home_baner_04 from '../assets/img/MainPage/1/4.png';
import home_baner__02_01 from '../assets/img/MainPage/1/i1.png';
import home_baner__02_02 from '../assets/img/MainPage/1/i2.png';
import home_baner__02_03 from '../assets/img/MainPage/1/i3.png';
import home_baner__02_04 from '../assets/img/MainPage/1/i4.png';
import home_baner__02_05 from '../assets/img/MainPage/1/i5.png';
import home_baner__02_06 from '../assets/img/MainPage/1/i6.png';
import home_baner__02_07 from '../assets/img/MainPage/1/i7.png';
import home_baner__02_08 from '../assets/img/MainPage/1/i8.png';

export const images = [
    logo,
    home_baner_01,
    home_baner_02,
    home_baner_03,
    home_baner_04,
    home_baner__02_01,
    home_baner__02_02,
    home_baner__02_03,
    home_baner__02_04,
    home_baner__02_05,
    home_baner__02_06,
    home_baner__02_07,
    home_baner__02_08,
];
