import React from 'react';
import classes from './GlobalReach.module.scss';
import { Trans, useTranslation } from 'react-i18next';

const GlobalReach = () => {
  const { t } = useTranslation();
  return (
    <section className="mt container">
      <div className={classes.globalReach}>
        <h2 className={[classes.title, 'font-40'].join(' ')}>
          <Trans>{t('about.global.title')}</Trans>
        </h2>
        <div className={[classes.content, 'font-20'].join(' ')}>
          <div className={classes.col}>
            <p>{t('about.global.text_01')}</p>
            <p>{t('about.global.text_02')}</p>
          </div>
          <div className={classes.col}>
            <p>{t('about.global.text_03')}</p>
            <p>{t('about.global.text_04')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GlobalReach;
