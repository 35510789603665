import React from "react";
import classes from './UnderBanner.module.scss';
import image_01 from '../../../assets/img/TradingPage/UnderBanner/image_01.png';
import image_02 from '../../../assets/img/TradingPage/UnderBanner/image_02.png';
import image_03 from '../../../assets/img/TradingPage/UnderBanner/image_03.png';
import image_04 from '../../../assets/img/TradingPage/UnderBanner/image_04.png';
import image_05 from '../../../assets/img/TradingPage/UnderBanner/image_05.png';
import { Trans, useTranslation } from "react-i18next";

const cards = [
    {
        image: image_01,
        title: 'trading.underbanner.card_1.title',
        text: 'trading.underbanner.card_1.text',
    },
    {
        image: image_02,
        title: 'trading.underbanner.card_2.title',
        text: 'trading.underbanner.card_2.text',
    },
    {
        image: image_03,
        title: 'trading.underbanner.card_3.title',
        text: 'trading.underbanner.card_3.text',
    },
    {
        image: image_04,
        title: 'trading.underbanner.card_4.title',
        text: 'trading.underbanner.card_4.text',
    },
    {
        image: image_05,
        title: 'trading.underbanner.card_5.title',
        text: 'trading.underbanner.card_5.text',
    },
];

const UnderBanner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.underBanner}>
            <div className={classes.cards}>
                {cards.map((card, index) =>
                    <div key={index} className={[classes.card, 'font-20'].join(' ')}>
                        <img className={classes.cardImage} src={card.image} alt=''/>
                        <h4 className={classes.cardTitle}>
                            {t(card.title)}
                        </h4>
                        <p className={classes.cardText}>
                            <Trans>
                                {t(card.text)}
                            </Trans>
                        </p>
                    </div>
                )}
            </div>
        </section>
    );
};

export default UnderBanner;
