import React from 'react';
import classes from './Three.module.scss';
import img1 from '../../../assets/img/AboutPage/3/3.png';
import { Trans, useTranslation } from 'react-i18next';

const Three = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.first, 'mt'].join(' ')}>
      <div className={[classes.firstBody, ''].join(' ')}>
        <div className="container">
          <div className={[classes.firstRow, ''].join(' ')}>
            <div className={[classes.firstRowLeft, ''].join(' ')}>
              <h2 className={[classes.firstRowLeftTitle, 'font-40'].join(' ')}>
                <Trans>{t('learn.step_3.title')}</Trans>
              </h2>
              <p className={[classes.firstRowLeftText, 'font-20'].join(' ')}>
                <Trans>{t('learn.step_3.text_01')}</Trans>
              </p>
              <p className={[classes.firstRowLeftText, 'font-20'].join(' ')}>
                <Trans>{t('learn.step_3.text_02')}</Trans>
              </p>
              <p
                className={[
                  classes.firstRowLeftText,
                  classes.fw600,
                  'font-20',
                ].join(' ')}
              >
                <Trans>{t('learn.step_3.text_03')}</Trans>
              </p>
              <p className={[classes.firstRowLeftText, 'font-20'].join(' ')}>
                <Trans>{t('learn.step_3.text_04')}</Trans>
              </p>
            </div>
            <div className={[classes.firstRowRight, ''].join(' ')}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
