import { useEffect, useState, useRef } from 'react';
import classes from './First.module.scss';
import Button from '../../GlobalComponents/Button/Button';
import imgMob from '../../../assets/img/MainPage/1/mob.png';
import img1 from '../../../assets/img/MainPage/1/1.png';
import img2 from '../../../assets/img/MainPage/1/2.png';
import img3 from '../../../assets/img/MainPage/1/3.png';
import img4 from '../../../assets/img/MainPage/1/4.png';
import imgI1 from '../../../assets/img/MainPage/1/i1.png';
import imgI2 from '../../../assets/img/MainPage/1/i2.png';
import imgI3 from '../../../assets/img/MainPage/1/i3.png';
import imgI4 from '../../../assets/img/MainPage/1/i4.png';
import imgI5 from '../../../assets/img/MainPage/1/i5.png';
import imgI6 from '../../../assets/img/MainPage/1/i6.png';
import imgI7 from '../../../assets/img/MainPage/1/i7.png';
import imgI8 from '../../../assets/img/MainPage/1/i8.png';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import { Trans, useTranslation } from 'react-i18next';
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const First = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState();

  const img1Ref = useRef(null);
  const img2Ref = useRef(null);
  const imgI1Ref = useRef(null);
  const imgI2Ref = useRef(null);
  const imgI3Ref = useRef(null);
  const imgI4Ref = useRef(null);
  const imgI5Ref = useRef(null);
  const imgI6Ref = useRef(null);
  const imgI7Ref = useRef(null);
  const imgI8Ref = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      // gsap.timeline().to(monetRef1.current, {
      //   scrollTrigger: {
      //     trigger: col1.current,
      //     start: "top bottom",
      //     end: "top center",
      //     scrub: 2,
      //   },
      //   y: 0,
      //   scale: 1,
      // });
      gsap.timeline().to(img1Ref.current, {
        y: 0,
        duration: 1,
        delay: 0.3,
        scale: 1,
        autoAlpha: 1,
      });
      gsap.timeline().to(img2Ref.current, {
        y: 0,
        duration: 1,
        delay: 0.3,
        autoAlpha: 1,
        scale: 1,
      });
      gsap.timeline().to(imgI1Ref.current, {
        y: 0,
        duration: 1,
        delay: 0.3,
        autoAlpha: 1,
        scale: 1,
        rotate: 0,
      });
      gsap.timeline().to(imgI2Ref.current, {
        y: 0,
        duration: 1,
        delay: 0.3,
        autoAlpha: 1,
        scale: 1,
        rotate: 0,
      });
      gsap.timeline().to(imgI3Ref.current, {
        y: 0,
        duration: 1,
        delay: 0.3,
        autoAlpha: 1,
        rotate: 0,
      });
      gsap.timeline().to(imgI4Ref.current, {
        y: 0,
        duration: 1,
        delay: 0.3,
        scale: 1,
        autoAlpha: 1,
        rotate: 0,
      });
      gsap.timeline().to(imgI5Ref.current, {
        y: 0,
        duration: 1,
        delay: 0.3,
        scale: 1,
        autoAlpha: 1,
        rotate: 0,
      });
      gsap.timeline().to(imgI6Ref.current, {
        y: 0,
        duration: 1,
        delay: 0.3,
        autoAlpha: 1,
        rotate: 0,
      });
      gsap.timeline().to(imgI7Ref.current, {
        y: 0,
        duration: 1,
        delay: 0.3,
        scale: 1,
        autoAlpha: 1,
        rotate: 0,
      });
      gsap.timeline().to(imgI8Ref.current, {
        y: 0,
        duration: 1,
        delay: 0.3,
        scale: 1,
        autoAlpha: 1,
        rotate: 0,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', (event) => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className={[classes.first, ''].join(' ')}>
      <div className={[classes.firstBody, ''].join(' ')}>
        <div className="container">
          <div className={[classes.firstWrap, ''].join(' ')}>
            <div className={[classes.firstInfo, ''].join(' ')}>
              <h2 className={[classes.firstInfoTitle, 'font-40'].join(' ')}>
                <Trans>
                  {t('home.banner.title')}
                </Trans>
              </h2>
              <p
                className={[classes.firstInfoText, classes.mb, 'font-20'].join(
                  ' '
                )}
              >
                {t('home.banner.text_01')}
              </p>
              <p className={[classes.firstInfoText, 'font-20'].join(' ')}>
                {t('home.banner.text_02')}
              </p>
              <div className={[classes.firstInfoBtn, ''].join(' ')}>
                <Button>{t('btn.trade_now')}</Button>
              </div>
            </div>
            <div className={[classes.firstImgs, ''].join(' ')}>
              <div className={[classes.firstImgsImg1, ''].join(' ')}>
                <img src={img1} alt="" />
              </div>
              <div className={[classes.firstImgsImg2, ''].join(' ')}>
                <img src={img2} alt="" />
              </div>
              <div
                className={[classes.firstImgsImg3, ''].join(' ')}
                ref={img1Ref}
              >
                <img src={img3} alt="" />
              </div>
              <div
                className={[classes.firstImgsImg4, ''].join(' ')}
                ref={img2Ref}
              >
                <img src={img4} alt="" />
              </div>

              <div
                className={[classes.firstImgsImgI1, ''].join(' ')}
                ref={imgI1Ref}
              >
                <img src={imgI1} alt="" />
              </div>
              <div
                className={[classes.firstImgsImgI2, ''].join(' ')}
                ref={imgI2Ref}
              >
                <img src={imgI2} alt="" />
              </div>
              <div
                className={[classes.firstImgsImgI3, ''].join(' ')}
                ref={imgI3Ref}
              >
                <img src={imgI3} alt="" />
              </div>
              <div
                className={[classes.firstImgsImgI4, ''].join(' ')}
                ref={imgI4Ref}
              >
                <img src={imgI4} alt="" />
              </div>
              <div
                className={[classes.firstImgsImgI5, ''].join(' ')}
                ref={imgI5Ref}
              >
                <img src={imgI5} alt="" />
              </div>
              <div
                className={[classes.firstImgsImgI6, ''].join(' ')}
                ref={imgI6Ref}
              >
                <img src={imgI6} alt="" />
              </div>
              <div
                className={[classes.firstImgsImgI7, ''].join(' ')}
                ref={imgI7Ref}
              >
                <img src={imgI7} alt="" />
              </div>
              <div
                className={[classes.firstImgsImgI8, ''].join(' ')}
                ref={imgI8Ref}
              >
                <img src={imgI8} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className={[classes.firstMob, ''].join(' ')}>
          <img src={imgMob} alt="" />
        </div>
      </div>
    </div>
  );
};

export default First;
