import React from "react";
import classes from './CareersAt.module.scss';
import { useTranslation } from "react-i18next";

const rows = [
    [
        '',
        'about.career.Type',
        'about.career.Location',
        'about.career.Department',
        'about.career.Experience',
    ],
    [
        'about.career.AndroidDeveloper',
        'about.career.Full-time',
        'about.career.Sofia',
        'about.career.Development',
        'about.career.Mid-Level',
    ],
    [
        'about.career.AutomationQAEngineer',
        'about.career.Full-time',
        'about.career.Sofia',
        'IT',
        'about.career.Mid-Level',
    ],
    [
        'about.career.CampaignManager',
        'about.career.Full-time',
        'about.career.Sofia',
        'about.career.Marketing',
        'about.career.Manager',
    ],
    [
        'about.career.CampaignManager',
        'about.career.Full-time',
        'about.career.Limassol',
        'about.career.Marketing',
        'about.career.Manager',
    ],
    [
        'about.career.ClientOnboardingAnalystJapaneseSpeaker',
        'about.career.Full-time',
        'about.career.Dubai',
        'about.career.Operations',
        'about.career.Associate',
    ],
    [
        'about.career.HeadofDigitalMarketing',
        'about.career.Full-time',
        'about.career.Sofia',
        'about.career.Marketing',
        'about.career.Manager',
    ],
    [
        'about.career.HeadofITEngineering',
        'about.career.Full-time',
        'about.career.Chicago',
        'IT',
        'about.career.SeniorManager',
    ],
    [
        'about.career.iOSDeveloper',
        'about.career.Full-time',
        'about.career.Sofia',
        'about.career.Development',
        'about.career.Experienced',
    ],
    [
        'about.career.JavaDeveloper',
        'about.career.Full-time',
        'about.career.Sofia',
        'about.career.Development',
        'about.career.Mid-Level',
    ],
];

const CareersAt = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.careersAt}>
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    {t('about.career.title')}
                </h2>
                <p className={[classes.text, 'font-20'].join(' ')}>
                    {t('about.career.text')}
                </p>
                <div className={classes.tableContainer}>
                    <div className={classes.tableWrap}>
                        <table>
                            <tbody>
                                {rows.map((row, rowIndex) =>
                                    <tr key={rowIndex}>
                                        {row.map((col, colIndex) =>
                                            <td key={colIndex}>
                                                {t(col)}
                                            </td>
                                        )}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CareersAt;
