import HomePage from '../pages/HomePage/HomePage';
import TradingPage from '../pages/TradingPage/TradingPage';
import AccountTypesPage from '../pages/AccountTypesPage/AccountTypesPage';
import LearnPage from '../pages/LearnPage/LearnPage';
import AboutPage from '../pages/AboutPage/AboutPage';
import FaqPage from '../pages/FaqPage/FaqPage';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/trading', element: TradingPage },
    { path: '/account-types', element: AccountTypesPage },
    { path: '/learn', element: AboutPage },
    { path: '/about-us', element: LearnPage },
    { path: '/faq', element: FaqPage },
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/trading', element: TradingPage },
    { path: '/:lang/account-types', element: AccountTypesPage },
    { path: '/:lang/learn', element: AboutPage },
    { path: '/:lang/about', element: LearnPage },
    { path: '/:lang/faq', element: FaqPage },
];

export const headerRoutes = [
    { id: 1, path: 'trading', text: 'header.trading' },
    { id: 2, path: 'account-types', text: 'header.accounts' },
    { id: 3, path: 'learn', text: 'header.learn' },
    { id: 4, path: 'about', text: 'header.about' },
    { id: 5, path: 'faq', text: 'header.faq' },
];
