import React, { useEffect, useRef, useState } from "react";
import classes from "./Eight.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import arrow from "../../../assets/img/AboutPage/8/arrow.gif";
import img1 from "../../../assets/img/AboutPage/7/1.png";
import img2 from "../../../assets/img/AboutPage/7/2.png";
import "swiper/css";
import { useTranslation } from "react-i18next";

const Eight = () => {
  const { t } = useTranslation();
  const [my_swiper, set_my_swiper] = useState({});
  const [items, setItems] = useState([
    { id: 1, active: true, year: "learn_letter_01" },
    { id: 2, active: false, year: "learn_letter_02" },
    { id: 3, active: false, year: "learn_letter_03" },
    { id: 4, active: false, year: "learn_letter_04" },
    { id: 5, active: false, year: "learn_letter_05" },
    { id: 6, active: false, year: "learn_letter_06" },
    { id: 7, active: false, year: "learn_letter_07" },
    { id: 8, active: false, year: "learn_letter_08" },
    { id: 9, active: false, year: "learn_letter_09" },
    { id: 10, active: false, year: "learn_letter_10" },
    { id: 11, active: false, year: "learn_letter_11" },
    { id: 12, active: false, year: "learn_letter_12" },
    { id: 13, active: false, year: "learn_letter_13" },
    { id: 14, active: false, year: "learn_letter_14" },
    { id: 15, active: false, year: "learn_letter_15" },
  ]);

  const [content, setContent] = useState([
    {
      id: 1,
      active: true,
      year: "learn_letter_01",
      contents: [
        {
          id: 1,
          title: "learn.line_01.01.title",
          text: "learn.line_01.01.text",
        },
        {
          id: 2,
          title: "learn.line_01.02.title",
          text: "learn.line_01.02.text",
        },
        {
          id: 3,
          title: "learn.line_01.03.title",
          text: "learn.line_01.03.text",
        },
      ],
    },
    {
      id: 2,
      active: false,
      year: "learn_letter_02",
      contents: [
        {
          id: 1,
          title: "learn.line_02.01.title",
          text: "learn.line_02.01.text",
        },
        {
          id: 2,
          title: "learn.line_02.02.title",
          text: "learn.line_02.02.text",
        },
        {
          id: 3,
          title: "learn.line_02.03.title",
          text: "learn.line_02.03.text",
        },
        {
          id: 4,
          title: "learn.line_02.04.title",
          text: "learn.line_02.04.text",
        },
        {
          id: 5,
          title: "learn.line_02.05.title",
          text: "learn.line_02.05.text",
        },
      ],
    },
    {
      id: 3,
      active: false,
      year: "learn_letter_03",
      contents: [
        {
          id: 1,
          title: "learn.line_03.01.title",
          text: "learn.line_03.01.text",
        },
      ],
    },
    {
      id: 4,
      active: false,
      year: "learn_letter_04",
      contents: [
        {
          id: 1,
          title: "",
          text: "learn.line_04.01.text",
        },
      ],
    },
    {
      id: 5,
      active: false,
      year: "learn_letter_05",
      contents: [
        {
          id: 1,
          title: "learn.line_05.01.title",
          text: "learn.line_05.01.text",
        },
        {
          id: 2,
          title: "learn.line_05.02.title",
          text: "learn.line_05.02.text",
        },
        {
          id: 3,
          title: "learn.line_05.03.title",
          text: "learn.line_05.03.text",
        },
        {
          id: 4,
          title: "learn.line_05.04.title",
          text: "learn.line_05.04.text",
        },
      ],
    },
    {
      id: 6,
      active: false,
      year: "learn_letter_06",
      contents: [
        {
          id: 1,
          title: "learn.line_06.01.title",
          text: "learn.line_06.01.text",
        },
      ],
    },
    {
      id: 7,
      active: false,
      year: "learn_letter_07",
      contents: [
        {
          id: 1,
          title: "learn.line_07.01.title",
          text: "learn.line_07.01.text",
        },
        {
          id: 2,
          title: "learn.line_07.02.title",
          text: "learn.line_07.02.text",
        },
        {
          id: 3,
          title: "learn.line_07.03.title",
          text: "learn.line_07.03.text",
        },
        {
          id: 4,
          title: "learn.line_07.04.title",
          text: "learn.line_07.04.text",
        },
        {
          id: 5,
          title: "learn.line_07.05.title",
          text: "learn.line_07.05.text",
        },
      ],
    },
    {
      id: 8,
      active: false,
      year: "learn_letter_08",
      contents: [
        {
          id: 1,
          title: "learn.line_08.01.title",
          text: "learn.line_08.01.text",
        },
        {
          id: 2,
          title: "learn.line_08.02.title",
          text: "learn.line_08.02.text",
        },
        {
          id: 3,
          title: "learn.line_08.03.title",
          text: "learn.line_08.03.text",
        },
        {
          id: 4,
          title: "learn.line_08.04.title",
          text: "learn.line_08.04.text",
        },
      ],
    },
    {
      id: 9,
      active: false,
      year: "learn_letter_09",
      contents: [
        {
          id: 1,
          title: "learn.line_09.01.title",
          text: "learn.line_09.01.text",
        },
        {
          id: 2,
          title: "learn.line_09.02.title",
          text: "learn.line_09.02.text",
        },
        {
          id: 3,
          title: "learn.line_09.03.title",
          text: "learn.line_09.03.text",
        },
        {
          id: 4,
          title: "learn.line_09.04.title",
          text: "learn.line_09.04.text",
        },
      ],
    },
    {
      id: 10,
      active: false,
      year: "learn_letter_10",
      contents: [
        {
          id: 1,
          title: "learn.line_10.01.title",
          text: "learn.line_10.01.text",
        },
        {
          id: 2,
          title: "learn.line_10.02.title",
          text: "learn.line_10.02.text",
        },
        {
          id: 3,
          title: "P&L",
          text: "learn.line_10.03.text",
        },
      ],
    },
    {
      id: 11,
      active: false,
      year: "learn_letter_11",
      contents: [
        {
          id: 1,
          title: "learn.line_11.01.title",
          text: "learn.line_11.01.text",
        },
      ],
    },
    {
      id: 12,
      active: false,
      year: "learn_letter_12",
      contents: [
        {
          id: 1,
          title: "learn.line_12.01.title",
          text: "learn.line_12.01.text",
        },
        {
          id: 2,
          title: "learn.line_12.02.title",
          text: "learn.line_12.02.text",
        },
        {
          id: 3,
          title: "learn.line_12.03.title",
          text: "learn.line_12.03.text",
        },
        {
          id: 4,
          title: "learn.line_12.04.title",
          text: "learn.line_12.04.text",
        },
      ],
    },
    {
      id: 13,
      active: false,
      year: "learn_letter_13",
      contents: [
        {
          id: 1,
          title: "learn.line_13.01.title",
          text: "learn.line_13.01.text",
        },
        {
          id: 2,
          title: "learn.line_13.02.title",
          text: "learn.line_13.02.text",
        },
      ],
    },
    {
      id: 14,
      active: false,
      year: "learn_letter_14",
      contents: [
        {
          id: 1,
          title: "learn.line_14.01.title",
          text: "learn.line_14.01.text",
        },
      ],
    },
    {
      id: 15,
      active: false,
      year: "learn_letter_15",
      contents: [
        {
          id: 1,
          title: "learn.line_15.01.title",
          text: "learn.line_15.01.text",
        },
      ],
    },
  ]);

  const handleSlideClick = (i) => {
    setItems(items.map((subI) => (
      { ...subI, active: i.id === subI.id }
    )));
    setContent(content.map((subI) => (
      { ...subI, active: i.id === subI.id }
    )));
  };

  return (
    <div className={[classes.second, "mt"].join(" ")}>
      <div className={classes.secondBody}>
        <div className="container">
          <div className={classes.secondImg1}>
            <img src={img1} alt="" />
          </div>
          <div className={classes.secondImg2}>
            <img src={img2} alt="" />
          </div>
          <h2 className={[classes.secondTitle, "font-40"].join(" ")}>
            {t('learn.line.title')}
          </h2>
          <div className={classes.secondRow}>
            <div
              className={classes.secondRowLeft}
              onClick={() => my_swiper.slidePrev()}
            >
              <img alt="History and milestones" src={arrow} />
            </div>
            <div className={classes.secondSlider}>
              <Swiper
                onInit={(e) => set_my_swiper(e)}
                slidesPerView={4}
                breakpoints={{
                  993: {
                    slidesPerView: 4,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  100: {
                    slidesPerView: 2,
                  },
                }}
              >
                {items.map((i) => {
                  return (
                    <SwiperSlide key={i.id}>
                      <div
                        className={`${classes.secondSliderSlide} ${i.active ? classes.active : ''}`}
                        onClick={() => handleSlideClick(i)}
                      >
                        <div className={classes.secondSliderSlideCircle}></div>
                        <p className={[classes.secondSliderSlideText, "font-20"].join(" ")}>
                          {t(i.year)}
                        </p>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div
              className={classes.secondRowRight}
              onClick={() => my_swiper.slideNext()}
            >
              <img alt="History and milestones" src={arrow} />
            </div>
          </div>
          {content.map((contentI) => contentI.active &&
            <div
              className={classes.secondContent}
              key={contentI.id}
            >
              <h2 className={[classes.secondContentYear, "font-45"].join(" ")}>
                {t(contentI.year)}
                <span style={{ textTransform: 'lowercase' }}>
                  {t(contentI.year)}
                </span>
              </h2>
              {contentI.contents.map((subI, index) => {
                return (
                  <div key={index}>
                    <p className={[classes.secondContentTitle, "font-25"].join(" ")}>
                      {t(subI.title)}
                    </p>
                    <p className={[classes.secondContentText, "font-20"].join(" ")}>
                      {t(subI.text)}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Eight;
