import React, { useState } from "react";
import classes from './ProfessionalClient.module.scss';
import { Trans, useTranslation } from "react-i18next";
import phone_01 from '../../../assets/img/AccountTypesPage/ProfessionalClient/phone_01.png';
import phone_02 from '../../../assets/img/AccountTypesPage/ProfessionalClient/phone_02.png';
import phone_03 from '../../../assets/img/AccountTypesPage/ProfessionalClient/phone_03.png';
import chart from '../../../assets/img/AccountTypesPage/ProfessionalClient/chart.png';

const tabs = [
    {
        title: 'account_types.proff.0.title',
        list: [
            'account_types.proff.0.list.0',
            'account_types.proff.0.list.1',
            'account_types.proff.0.list.2',
        ],
    },
    {
        title: 'account_types.proff.1.title',
        text: [
            'account_types.proff.1.text.0',
            'account_types.proff.1.text.1',
        ],
    },
    {
        title: 'account_types.proff.2.title',
        text: [
            'account_types.proff.2.text.0',
            'account_types.proff.2.text.1',
        ],
    },
];

const ProfessionalClient = () => {
    const { t } = useTranslation();
    const [ currentTab, setCurrentTab ] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.professionalClient}>
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    <Trans>
                        {t('account_types.proff.title')}
                    </Trans>
                </h2>
                <p className={[classes.text, 'font-20'].join(' ')}>
                    {t('account_types.proff.text')}
                </p>
                <div className={classes.content}>
                    {tabs.map((tab, index) =>
                        <div 
                            onClick={() => setCurrentTab(index)}
                            className={`${classes.tab} ${currentTab === index && classes.tabActive} no-select`}
                            key={index}
                        >
                            <h4 className={[classes.tabTitle, 'font-20'].join(' ')}>
                                {t(tab.title)}
                            </h4>
                            <div className={classes.tabContent}>
                                {tab.text &&
                                    <div className={classes.tabText}>
                                        {tab.text.map((text, textIndex) =>
                                            <p key={textIndex}>
                                                {t(text)}
                                            </p>
                                        )}
                                    </div>
                                }
                                {tab.list &&
                                    <ul>
                                        {tab.list.map((li, liIndex) =>
                                            <li key={liIndex}>
                                                {t(li)}
                                            </li>
                                        )}
                                    </ul>
                                }
                            </div>
                        </div>
                    )}
                </div>
                <img className={classes.chart} src={chart} alt=''/>
                <img 
                    className={`${classes.phone} ${classes.phone_01} ${currentTab === 0 && classes.phoneActive}`}
                    src={phone_01} 
                    alt=''
                />
                <img 
                    className={`${classes.phone} ${classes.phone_02} ${currentTab === 1 && classes.phoneActive}`}
                    src={phone_02} 
                    alt=''
                />
                <img 
                    className={`${classes.phone} ${classes.phone_03} ${currentTab === 2 && classes.phoneActive}`}
                    src={phone_03} 
                    alt=''
                />
            </div>
        </section>
    );
};

export default ProfessionalClient;
