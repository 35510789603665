import React, { useState } from 'react';
import classes from './IndustryLeading.module.scss';
import Button from '../../GlobalComponents/Button/Button';
import icon_01 from '../../../assets/img/TradingPage/IndustryLeading/icon_01.svg';
import icon_02 from '../../../assets/img/TradingPage/IndustryLeading/icon_02.svg';
import icon_03 from '../../../assets/img/TradingPage/IndustryLeading/icon_03.svg';
import icon_04 from '../../../assets/img/TradingPage/IndustryLeading/icon_04.svg';
import icon_05 from '../../../assets/img/TradingPage/IndustryLeading/icon_05.svg';
import { Trans, useTranslation } from 'react-i18next';

const tabs = [
  {
    id: 0,
    icon: icon_01,
    rows: [
      ['EURUSD', '1.07714', '1.07714', '0.00'],
      ['GBPUSD', '1.22291', '1.22295', '0.40'],
      ['AUDUSD', '0.66987', '0.66989', '0.20'],
      ['USDCHF', '0.92287', '0.92291', '0.40'],
      ['USDJPY', '132.406', '132.406', '0.00'],
      ['USDCAD', '1.37004', '1.37006', '0.20'],
    ],
  },
  {
    id: 1,
    icon: icon_02,
    rows: [
      ['SPX500', '4,005.97', '4,006.38', '0.41'],
      ['AUS200', '7,014.80', '7,016.00', '1.20'],
      ['NAS100', '12,748.18', '12,748.98', '0.80'],
      ['UK100', '7,545.31', '7,547.61', '2.30'],
      ['GER40', '15,268.34', '15,268.94', '0.60'],
      ['US30', '1.32,559.83', '32,561.53', '1.70'],
    ],
  },
  {
    id: 2,
    icon: icon_03,
    rows: [
      ['XAUUSD', '1,941.54', '1,941.63', '0.1'],
      ['XAGUSD', '22.392', '22.422', '0.3'],
      ['COPPER', '3.9815', '3.9849', '0.3'],
    ],
  },
  {
    id: 3,
    icon: icon_04,
    rows: [
      ['BTCUSD', '28,263.84', '28,289.29', '25.45'],
      ['ETHUSD', '1,802.23', '1,807.23', '5.00'],
      ['BCHUSD', '131.20', '134.43', '3.23'],
      ['LTCUSD', '80.47', '82.48', '2.01'],
      ['XMRUSD', '153.28', '162.32', '9.04'],
      ['XLMUSD', '0.0956', '0.1018', '0.6'],
      ['DOGEUSD', '0.0751', '0.0767', '0.2'],
    ],
  },
  {
    id: 4,
    icon: icon_05,
    rows: [
      ['AMAZON', '100.41', '100.49', '0.08'],
      ['FACEBOOK', '331.68', '331.81', '0.13'],
      ['GOOGLE', '104.76', '104.83', '0.07'],
      ['APPLE', '159.17', '159.24', '0.07'],
      ['ALIBABA', '83.58', '83.65', '0.07'],
      ['GOLDMAN', '317.06', '317.17', '0.11'],
    ],
  },
];

const IndustryLeading = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(0);
  return (
    <section className="mt container">
      <div className={classes.industryLeading}>
        <div className={classes.textBlock}>
          <h2 className={classes.title}>{t('trading.leading.title')}</h2>
          <h3 className={classes.subtitle}>{t('trading.leading.subtitle')}</h3>
          <ul>
            <li>{t('trading.leading.list_01')}</li>
            <li>{t('trading.leading.list_02')}</li>
            <li>{t('trading.leading.list_03')}</li>
            <li>{t('trading.leading.list_04')}</li>
            <li>{t('trading.leading.list_05')}</li>
            <li>
                <Trans>
                    {t('trading.leading.list_06')}
                </Trans>
            </li>
          </ul>
          <div className={classes.btn}>
            <Button secondary>{t('btn.open_account')}</Button>
          </div>
        </div>
        <div className={classes.contentWrap}>
          <div className={classes.buttons}>
            {tabs.map((tab) => (
              <div
                key={tab.id}
                className={`${classes.button} ${
                  tab.id === currentTab && classes.buttonActive
                }`}
                onClick={() => setCurrentTab(tab.id)}
              >
                <img className={classes.buttonIcon} src={tab.icon} alt="" />
              </div>
            ))}
          </div>
          <div className={classes.tableWrap}>
            <div className={classes.tableContent}>
              <table>
                <tbody>
                  <tr>
                    <td>{t('trading.thead.td_01')}</td>
                    <td>{t('trading.thead.td_02')}</td>
                    <td>{t('trading.thead.td_03')}</td>
                    <td>{t('trading.thead.td_04')}</td>
                  </tr>
                  {tabs[currentTab].rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((col, colIndex) => (
                        <td key={colIndex}>{col}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className={classes.desc}>{t('trading.leading.desc')}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IndustryLeading;
