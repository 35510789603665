import React from "react";
import classes from "./Footer.module.scss";
import { useTranslation } from "react-i18next";
import footer_city from '../../../assets/img/footer_city.png';

const Footer = ({ city = false }) => {
  const { t } = useTranslation();
  const hostname = document.location.hostname;
  return (
    <footer className={[classes.footerWrap, 'mt', 'container'].join(' ')}>
      {city && <img className={classes.city} src={footer_city} alt=''/>}
      <div className={classes.footer}>
        <div className={classes.info}>
          <h5 className={classes.title}>
            {t('footer.disclaimer.title')}
          </h5>
          <p>
            {t('footer.disclaimer.text_01')}
          </p>
          <p>
            {t('footer.disclaimer.text_02')}
          </p>
          <p>
            {t('footer.disclaimer.text_03')}
          </p>
          <p>
            {t('footer.disclaimer.text_04')}
          </p>
          <h5 className={classes.title}>
            {t('footer.about.title')}
          </h5>
          <p>
            {t('footer.about.text_01')}
          </p>
          <p>
            {t('footer.about.text_02')}
          </p>
          <p>
            {t('footer.about.text_03')}
          </p>
          <p>
            {t('footer.about.text_04')}
          </p>
        </div>
        <div className={classes.docs}>
          <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
            {t('footer.docs.terms')}
          </a>
          <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
            {t('footer.docs.privacy')}
          </a>
          <a href="/docs/AML_POLICY.pdf" target="_blank">
            {t('footer.docs.aml')}
          </a>
        </div>
        <div className={classes.contacts}>
          <p>
            {t('footer.contact')}
          </p>
          <div className={classes.contactUs}>
            <a href="tel:+441863440208">
              +441863440208
            </a>
            <a href={`mailto:support@${hostname}`}>
              support@{hostname}
            </a>
          </div>
        </div>
      </div>
      <p className={classes.rights}>
        {t('footer.rights')}
      </p>
    </footer>
  );
};

export default Footer;
