import React from 'react';
import classes from './Button.module.scss';
import Cookies from 'js-cookie';

const Button = ({ children, login, secondary }) => {
  const lang = Cookies.get('i18next');
  const cabinelUrl = process.env.REACT_APP_CABINET_URL;
  return (
    <a
      href={`https://${cabinelUrl}/${login ? 'login' : 'signup'}?lang=${lang}`}
      className={`${classes.button} ${secondary && classes.secondary}`}
    >
      <span>{children}</span>
    </a>
  );
};

export default Button;
