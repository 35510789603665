import React, { useState } from 'react';
import classes from './Second.module.scss';
import img1 from '../../../assets/img/MainPage/3/1.png';
import Button from '../../GlobalComponents/Button/Button';
import { Trans, useTranslation } from 'react-i18next';

const Second = () => {
  const { t } = useTranslation();
  const [tabs, setTabs] = useState([
    {
      id: 1,
      head: 'home.access.card_01.title',
      text: 'home.access.card_01.text',
      active: true,
    },
    {
      id: 2,
      head: 'home.access.card_02.title',
      text: 'home.access.card_02.text',
      active: false,
    },
    {
      id: 3,
      head: 'home.access.card_03.title',
      text: 'home.access.card_03.text',
      active: false,
    },
  ]);

  return (
    <div className={[classes.second, ''].join(' ')}>
      <h2 className={[classes.secondTitle, 'font-40'].join(' ')}>
        <Trans>{t('home.access.title')}</Trans>
      </h2>
      <div className={[classes.secondBody, ''].join(' ')}>
        <div className="container">
          <div className={[classes.secondWrapper, ''].join(' ')}>
            <div className={[classes.secondTabs, ''].join(' ')}>
              <div className={[classes.secondTabsHeaders, ''].join(' ')}>
                {tabs.map((headerItem) => {
                  return (
                    <p
                      key={headerItem.id}
                      className={
                        headerItem.active
                          ? [
                              classes.secondTabsHeadersItem,
                              classes.active,
                              '',
                            ].join(' ')
                          : [classes.secondTabsHeadersItem, ''].join(' ')
                      }
                      onClick={() => {
                        setTabs(
                          tabs.map((subI) => {
                            if (subI.id === headerItem.id) {
                              return { ...subI, active: true };
                            } else {
                              return { ...subI, active: false };
                            }
                          })
                        );
                      }}
                    >
                      {t(headerItem.head)}
                    </p>
                  );
                })}
              </div>

              {tabs.map((contentItem) => {
                return (
                  <div
                    key={contentItem.id}
                    className={
                      contentItem.active
                        ? [
                            classes.secondTabsContent,
                            classes.active,
                            'font-20',
                          ].join(' ')
                        : [classes.secondTabsContent, 'font-20'].join(' ')
                    }
                  >
                    {t(contentItem.text)}
                  </div>
                );
              })}
              <div className={[classes.secondTabsBtn, ''].join(' ')}>
                <Button secondary={'true'}>{t('btn.trade_now')}</Button>
              </div>
            </div>

            <div className={[classes.secondImg, ''].join(' ')}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
