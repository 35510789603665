import React, { useEffect, useRef } from "react";
import classes from './LeadingBroker.module.scss';
import Button from "../../GlobalComponents/Button/Button";
import { Trans, useTranslation } from "react-i18next";
import globe from '../../../assets/img/LearnPage/LeadingBroker/globe.png';
import image_01 from '../../../assets/img/LearnPage/LeadingBroker/image_01.png';
import image_02 from '../../../assets/img/LearnPage/LeadingBroker/image_02.png';
import image_03 from '../../../assets/img/LearnPage/LeadingBroker/image_03.png';
import image_04 from '../../../assets/img/LearnPage/LeadingBroker/image_04.png';
import image_05 from '../../../assets/img/LearnPage/LeadingBroker/image_05.png';
import image_06 from '../../../assets/img/LearnPage/LeadingBroker/image_06.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const LeadingBroker = () => {
    const { t } = useTranslation();
    const imageRef_01 = useRef(null);
    const imageRef_02 = useRef(null);
    const imageRef_03 = useRef(null);
    const imageRef_04 = useRef(null);
    const imageRef_05 = useRef(null);
    const imageRef_06 = useRef(null);
    const imageWrapRef_01 = useRef(null);
    const imageWrapRef_02 = useRef(null);
    const imageWrapRef_03 = useRef(null);
    const imageWrapRef_04 = useRef(null);
    const imageWrapRef_05 = useRef(null);
    const imageWrapRef_06 = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            setTimeout(() => {
                gsap.timeline().fromTo(imageRef_01.current, {
                    y: 100,
                    x: -40,
                }, {
                    y: 0,
                    x: 0,
                    scrollTrigger: {
                        trigger: imageWrapRef_01.current,
                        end: "+=200px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(imageRef_02.current, {
                    y: 60,
                    x: -20,
                }, {
                    y: 0,
                    x: 0,
                    scrollTrigger: {
                        trigger: imageWrapRef_02.current,
                        end: "+=200px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(imageRef_03.current, {
                    y: 80,
                    x: -70,
                }, {
                    y: 0,
                    x: 0,
                    scrollTrigger: {
                        trigger: imageWrapRef_03.current,
                        end: "+=200px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(imageRef_04.current, {
                    y: 40,
                    x: 40,
                }, {
                    y: 0,
                    x: 0,
                    scrollTrigger: {
                        trigger: imageWrapRef_04.current,
                        end: "+=200px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(imageRef_05.current, {
                    y: 60,
                    x: 20,
                }, {
                    y: 0,
                    x: 0,
                    scrollTrigger: {
                        trigger: imageWrapRef_05.current,
                        end: "+=200px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(imageRef_06.current, {
                    y: 80,
                    x: 100,
                }, {
                    y: 0,
                    x: 0,
                    scrollTrigger: {
                        trigger: imageWrapRef_06.current,
                        end: "+=200px",
                        scrub: 3,
                    }
                });
            }, 200);
        }
    }, []);
    return (
        <section className="mt container">
            <div className={classes.leadingBroker}>
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    <Trans>
                        {t('about.leading')}
                    </Trans>
                </h2>
                <div className={classes.btn}>
                    <Button>{t('btn.start_trading')}</Button>
                </div>
                <div className={classes.images}>
                    <img className={classes.globe} src={globe} alt=''/>
                    <div ref={imageWrapRef_01} className={[classes.image_01, classes.image].join(' ')}>
                        <img ref={imageRef_01} src={image_01} alt=''/>
                    </div>
                    <div ref={imageWrapRef_02} className={[classes.image_02, classes.image].join(' ')}>
                        <img ref={imageRef_02} src={image_02} alt=''/>
                    </div>
                    <div ref={imageWrapRef_03} className={[classes.image_03, classes.image].join(' ')}>
                        <img ref={imageRef_03} src={image_03} alt=''/>
                    </div>
                    <div ref={imageWrapRef_04} className={[classes.image_04, classes.image].join(' ')}>
                        <img ref={imageRef_04} src={image_04} alt=''/>
                    </div>
                    <div ref={imageWrapRef_05} className={[classes.image_05, classes.image].join(' ')}>
                        <img ref={imageRef_05} src={image_05} alt=''/>
                    </div>
                    <div ref={imageWrapRef_06} className={[classes.image_06, classes.image].join(' ')}>
                        <img ref={imageRef_06} src={image_06} alt=''/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LeadingBroker;
