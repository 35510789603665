import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import First from "../../components/AboutComponents/First/First";
import Second from "../../components/AboutComponents/Second/Second";
import Three from "../../components/AboutComponents/Three/Three";
import Four from "../../components/AboutComponents/Four/Four";
import Five from "../../components/AboutComponents/Five/Five";
import Six from "../../components/AboutComponents/Six/Six";
import Seven from "../../components/AboutComponents/Seven/Seven";
import Eight from "../../components/AboutComponents/Eight/Eight";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <title>Learn To Trade</title>
        <meta
          name="title"
          content="Learn More About Curion Finance Forex Broker and Our Mission"
        />
        <meta
          property="og:title"
          content="Learn More About Curion Finance Forex Broker and Our Mission"
        />
        <meta
          property="twitter:title"
          content="Learn More About Curion Finance Forex Broker and Our Mission"
        />

        <meta
          name="description"
          content="Discover who we are and what drives us at Curion Finance Forex Broker. Our team is dedicated to providing you with the best trading experience and helping you achieve your financial goals."
        />
        <meta
          property="og:description"
          content="Discover who we are and what drives us at Curion Finance Forex Broker. Our team is dedicated to providing you with the best trading experience and helping you achieve your financial goals."
        />
        <meta
          property="twitter:description"
          content="Discover who we are and what drives us at Curion Finance Forex Broker. Our team is dedicated to providing you with the best trading experience and helping you achieve your financial goals."
        />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <First />
        <Second />
        <Three />
        <Four />
        <Five />
        <Six />
        <Seven />
        <Eight />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AboutPage;
