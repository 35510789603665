import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from '../../GlobalComponents/Button/Button';
import plate_01 from '../../../assets/img/AccountTypesPage/Banner/plate_01.png';
import plate_02 from '../../../assets/img/AccountTypesPage/Banner/plate_02.png';
import plate_03 from '../../../assets/img/AccountTypesPage/Banner/plate_03.png';
import plate_04 from '../../../assets/img/AccountTypesPage/Banner/plate_04.png';
import icon_01 from '../../../assets/img/AccountTypesPage/Banner/icon_01.png';
import icon_02 from '../../../assets/img/AccountTypesPage/Banner/icon_02.png';
import laptop from '../../../assets/img/AccountTypesPage/Banner/laptop.png';
import img_mob from '../../../assets/img/AccountTypesPage/Banner/img_mob.png';
import { gsap } from "gsap";
gsap.config({
    force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();
    const itemRef_01 = useRef(null);
    const itemRef_02 = useRef(null);
    const itemRef_03 = useRef(null);
    const itemRef_04 = useRef(null);
    const itemRef_05 = useRef(null);
    const itemRef_06 = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.fromTo(itemRef_01.current, {
                opacity: 0,
                y: 140,
                rotate: 30,
            }, {
                opacity: 1,
                y: 0,
                rotate: 0,
                duration: 2,
            });
            gsap.fromTo(itemRef_02.current, {
                opacity: 0,
                y: 80,
                rotate: -20,
            }, {
                opacity: 1,
                y: 0,
                rotate: 0,
                duration: 2,
            });
            gsap.fromTo(itemRef_03.current, {
                opacity: 0,
                y: 180,
                rotate: -40,
            }, {
                opacity: 1,
                y: 0,
                rotate: 0,
                duration: 2,
            });
            gsap.fromTo(itemRef_04.current, {
                opacity: 0,
                y: 160,
                rotate: 20,
            }, {
                opacity: 1,
                y: 0,
                rotate: 0,
                duration: 2,
            });
            gsap.fromTo(itemRef_05.current, {
                opacity: 0,
                y: 150,
            }, {
                opacity: 1,
                y: 0,
                duration: 2,
            });
            gsap.fromTo(itemRef_06.current, {
                opacity: 0,
                y: 120,
                rotate: 40,
            }, {
                opacity: 1,
                y: 0,
                rotate: 0,
                duration: 2,
            });
        }
    }, []);
    return (
        <section className="container">
            <div className={classes.banner}>
                <div className={classes.content}>
                    <h1 className={[classes.title, 'font-40'].join(' ')}>
                        <Trans>
                            {t('account_types.banner.title')}
                        </Trans>
                    </h1>
                    <p className={[classes.text, 'font-20'].join(' ')}>
                        {t('account_types.banner.text')}
                    </p>
                    <div className={classes.btn}>
                        <Button>{t('btn.open_account')}</Button>
                    </div>
                </div>
                <div className={classes.images}>
                    <img className={classes.laptop} src={laptop} alt=''/>
                    <img ref={itemRef_01} className={classes.plate_01} src={plate_01} alt=''/>
                    <img ref={itemRef_02} className={classes.plate_02} src={plate_02} alt=''/>
                    <img ref={itemRef_03} className={classes.plate_03} src={plate_03} alt=''/>
                    <img ref={itemRef_04} className={classes.plate_04} src={plate_04} alt=''/>
                    <img ref={itemRef_05} className={classes.icon_01} src={icon_01} alt=''/>
                    <img ref={itemRef_06} className={classes.icon_02} src={icon_02} alt=''/>
                </div>
                <img className={classes.img_mob} src={img_mob} alt=''/>
            </div>
        </section>
    );
};

export default Banner;
