import { useEffect, useState, useRef } from "react";
import classes from "./First.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import Faq from "../Faq/Faq";
import Faq2 from "../Faq2/Faq";
import Faq3 from "../Faq3/Faq";
import Faq4 from "../Faq4/Faq";
import img1 from "../../../assets/img/FaqPage/1/1.png";
import img2 from "../../../assets/img/FaqPage/1/2.png";
import { useTranslation } from "react-i18next";

const First = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState();

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  const [tabs, setTabs] = useState([
    { id: 1, text: "faq.banner.tab_01", active: true },
    {
      id: 2,
      text: "faq.banner.tab_02",
      active: false,
    },
    { id: 3, text: "faq.banner.tab_03", active: false },
    { id: 4, text: "faq.banner.tab_04", active: false },
  ]);

  return (
    <div className={[classes.first, ""].join(" ")}>
      <div className={[classes.firstBody, "mb"].join(" ")}>
        <div className="container">
          <h2 className={[classes.firstTitle, "font-40"].join(" ")}>
            {t('faq.banner.title')}
          </h2>
          <div className={[classes.firstBtn, ""].join(" ")}>
            <Button>{t('btn.open_account')}</Button>
          </div>
          <div className={[classes.firstBlue, ""].join(" ")}>
            <div className={[classes.firstBlueImg, ""].join(" ")}>
              <img src={img2} alt="FAQ & Support at Curion Finance" />
            </div>
            {width > 992 && (
              <div className={[classes.firstBlueTabs, ""].join(" ")}>
                {tabs.map((item) => {
                  return (
                    <p
                      key={item.id}
                      onClick={() => {
                        setTabs(
                          tabs.map((subI) => {
                            if (item.id === subI.id) {
                              return { ...subI, active: true };
                            } else {
                              return { ...subI, active: false };
                            }
                          })
                        );
                      }}
                      className={
                        item.active
                          ? [
                              classes.firstBlueTabsTab,
                              classes.active,
                              "font-20",
                            ].join(" ")
                          : [classes.firstBlueTabsTab, "font-20"].join(" ")
                      }
                    >
                      <img src={img1} alt="" />
                      {t(item.text)}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
          {width < 993 && (
            <div className={[classes.firstBlueTabs, ""].join(" ")}>
              {tabs.map((item) => {
                return (
                  <p
                    key={item.id}
                    onClick={() => {
                      setTabs(
                        tabs.map((subI) => {
                          if (item.id === subI.id) {
                            return { ...subI, active: true };
                          } else {
                            return { ...subI, active: false };
                          }
                        })
                      );
                    }}
                    className={
                      item.active
                        ? [
                            classes.firstBlueTabsTab,
                            classes.active,
                            "font-20",
                          ].join(" ")
                        : [classes.firstBlueTabsTab, "font-20"].join(" ")
                    }
                  >
                    <img src={img1} alt="" />
                    {t(item.text)}
                  </p>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {tabs[0].active && <Faq />}
      {tabs[1].active && <Faq2 />}
      {tabs[2].active && <Faq3 />}
      {tabs[3].active && <Faq4 />}
    </div>
  );
};

export default First;
